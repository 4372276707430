import {SubmissionError} from "redux-form";
import {
    REGISTER_AFFILIATE_ACCOUNT_RECEIVED,
    REGISTER_AFFILIATE_ERROR,
    REGISTER_CODE_REQUEST,
    REGISTER_CODE_SUCCESS
} from "../constants";
import {formValidate, minChar, requiredField, validateEmail, validateTextByPattern} from "../common/form";
import {requests} from "../agent";
import {parseApiErrors} from "../common/apiUtils";


export const registerCodeRequest = (data) => {
    return {
        type: REGISTER_CODE_REQUEST,
        data,
    }
};

/**
 * RegisterCode check
 * @param code
 * @returns {function(*): Q.Promise<any>}
 */
export const registerCodeAttempt = (code) => {

    let mapFields = [
        {
            field: 'code',
            text: 'Pole kodu nie może pozostać puste!',
            method: requiredField
        }
    ];

    let validate = formValidate(mapFields, {code})
    let body = {
        code: code
    }
    if (validate) {
        throw new SubmissionError(validate)
    } else {
        return (dispatch) => {
            dispatch(registerCodeRequest());
            return requests.post('/affiliate/verify_code', body)
              .then(response => dispatch(registerCodeSuccess(response)))
              .catch(() => {
                  throw new SubmissionError({
                      _error: 'Podany kod jest nieprawidłowy'
                  })
              });
        }
    }
};


export const registerCodeSuccess = (data) => {
    return {
        type: REGISTER_CODE_SUCCESS,
        data,
    }
};



export const registerAffiliateAccount = (fields) => {

    let mapFields = [
        {
            field: 'name',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole imię nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Imię powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Imię powinno zawierać tylko litery.',
                    params: { pattern: /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]*$/u }
                }
            ]
        },
        {
            field: 'surname',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole nazwisko nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Nazwisko powinno zawierać minimum 3 znaki.',
                    params: {limit: 3}
                },
                {
                    method: validateTextByPattern,
                    text: 'Tylko litery, myślnik oraz spacja.',
                    params: {pattern: /^[a-zćęółśążźń /-]*$/iu}
                }
            ]
        },
        {
            field: 'email',
            text: 'Podany adres email nie jest prawidłowy!',
            method: validateEmail
        },
        {
            field: 'phone',
            methods: [
                {
                    method: requiredField,
                    text: 'Pole telefon nie może pozostać puste!'
                },
                {
                    method: minChar,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {limit: 15}
                },
                {
                    method: validateTextByPattern,
                    text: 'Poprawny format telefonu +48 999 999 999',
                    params: {pattern: /^\+48 [0-9 ]{11}$/iu}
                }
            ]
        },
        {
            field: 'position',
            method: requiredField,
            text: 'Pole stanowisko nie może pozostać puste!'
        },
        {
            field: 'acceptTerms',
            text: 'Akceptacja regulaminu jest wymagana!',
            method: requiredField
        }
    ];

    let validate = formValidate(mapFields, fields)

    if (validate) {
        throw new SubmissionError(validate)
    } else {
        let body = {
            code: window.sessionStorage.getItem('code'),
            user: fields
        }

        return (dispatch) => {
            return requests.post(`/orders`, body)
                .then(response => dispatch(registerAffiliateAccountReceived(response)))
                .catch((error) => {
                    dispatch(registerAffiliateError(error))
                    throw new SubmissionError(parseApiErrors(error))

                })
        }

    }
}

export const registerAffiliateAccountReceived = (data) => {
    return {
        type: REGISTER_AFFILIATE_ACCOUNT_RECEIVED,
        data,

    }
}

export const registerAffiliateError = (error) => ({
    type: REGISTER_AFFILIATE_ERROR,
    error
})